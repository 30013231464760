

import Main from './components/Main'
import Team from './components/Team'
import Product from './components/Product'
import Vision from './components/Vision'
import Contact from './components/Contact'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/team" element={<Team/>} />
        <Route path="/product" element={<Product/>} />
        <Route path="/vision" element={<Vision/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>

    </Router>
    
  );
}

export default App;
