import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ContactHero from './ContactHero'
import ContactForm from './ContactForm'


const Contact = () => {
  return (
      <>
    <Header/>
    <ContactHero/>
    <ContactForm/>
    <Footer/>
      </>
  )
}

export default Contact