import React from 'react'
import Header from './Header'
import TeamHero from './TeamHero'
import TeamMain from './TeamMain'
import Footer from './Footer'

const Team = () => {
  return (
    <>
    <Header/>
    <TeamHero/>
    <TeamMain/>
    <Footer/>
    </>
  )
}

export default Team