import React from "react";
import Contactgif from "./assets/1zes.webp";

const ContactHero = () => {
  return (
    <>
      <section className="text-white font-Mulish body-font bg-black">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="hero"
            src={Contactgif}
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300 md:text-xl lg:text-4xl">
              {" "}
              Contact Us{" "}
            </h1>
            <p className="mb-8 leading-relaxed text-xl sm:text-lg ">
              We are always happy to have a chat with you.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactHero;
