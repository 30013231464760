import React from 'react'
import Header from './Header'
import VisionHero from './VisionHero'
import Footer from './Footer'


const Vision = () => {
  return (
    <>
    <Header/>
    <VisionHero/>
    <Footer/>
    
    </>
  )
}

export default Vision