import React from "react";
import Cosmic from "./assets/cosmic.webp";
import {Link} from "react-router-dom"

const Hero = () => {
  return (
    <>
      <section className="text-white font-Mulish body-font bg-black">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="hero"
            src={Cosmic}
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font  text-xl mb-4 font-medium text-gray-300 md:text-3xl">
              {" "}
              The Great shift "Serving Curiosity" for a Sustainable and
              Conscious Planet.{" "}
            </h1>
            <p className="mb-8 leading-relaxed md:text-xl  ">For all Humanity and Beings</p>
            <div className="flex justify-center">
              <Link to="/vision">


              <button className="inline-flex text-black bg-white border-0 py-2 px-6 focus:outline-none hover:bg-gray-500 rounded text-lg">
                Know More
              </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
