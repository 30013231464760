import React from 'react'
import Rig from "./assets/rig2.webp"

const MainTab2 = () => {
  return (
    <>
    <section className="text-white body-font bg-black ">
      <div className="container px-5 py-4 mx-auto flex flex-wrap">
        
        <div className="flex flex-col flex-wrap lg:py-48 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
          <div className="flex flex-col mb-10 lg:items-start items-center">
            <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-500 mb-5">
              <svg
                fill="none"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                className="w-6 h-6"
                viewBox="0 0 24 24"
              >
                <path d="M42 12h-4l-3 5L9 3l-3 9H2"></path>
              </svg>
            </div>
            <div className="flex-grow mt-4">
              <h2 className="text-gray-300 font-Mulish text-2xl title-font font-medium mb-3">
              The Time and Space Advantage
              </h2>
              <p className="leading-relaxed font-Mulish text-base">
              Leverage technology towards end to end product visibility, efficiency and interoperability to make relevant information accessible to all chain stakeholders.

A goal towards fostering global sustainable Innovative practices within business, trade & human lifestyle
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
          <img
            alt="feature"
            className="object-cover object-center h-full w-full"
            src={Rig}
          />
        </div>
      </div>
    </section>
  </>
  )
}

export default MainTab2