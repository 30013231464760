import React from 'react'

const Video = () => {
  return (
    <>
    <div className="aspect-w-16 font-Mulish aspect-h-9">
    <iframe width="560" height="315" src="https://www.youtube.com/embed/rrICPrgu7ow" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
    
    </>
  )
}

export default Video