import React from 'react'
import Header from './Header'
import Hero from './Hero'
import Statement from './Statement'
import MainTab1 from './MainTab1'
import MainTab2 from './MainTab2'
import Video from "./Video"
import Footer from "./Footer"

const Main = () => {
  return (
    <>
    <Header/>
    <Hero/>
    <Statement/>
    <MainTab1/>
    <MainTab2/>
    <Video/>
    <Footer/>
    </>
  )
}

export default Main