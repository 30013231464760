import React from 'react'
import {Link} from 'react-router-dom'

const ProductMain = () => {
  return (
    <>
    
<div className="flex items-center font-Mulish justify-center h-screen bg-black">
  <div className="container">
    <div className="bg-gray-900 rounded-lg shadow-lg p-5 md:p-20 mx-2">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-400 sm:text-5xl sm:leading-none md:text-6xl">
          Kaala<span className="text-gray-600">.ai</span>
        </h2>
        <h3 className='text-xl md:text-3xl mt-10 text-gray-300'>Coming Soon</h3>
        <p className="text-md md:text-xl mt-10 text-gray-300"><a className="hover:underline text-gray-400" href="https://kaala.ai">Kaala.ai : </a> Stay tuned at our social media for product launch information.</p>
      </div>
      <div className="flex flex-wrap mt-10 justify-center">
        <div className="m-3">
          <Link to="/" title="Kaala"
             className="md:w-32 bg-gray-500 tracking-wide text-gray-800 font-bold rounded border-2 border-gray-200 hover:border-gray-600  hover:text-white shadow-md py-2 px-6 inline-flex items-center">
            <span className="mx-auto">Go back</span>
          </Link>
        </div>
        <div className="m-3">
          <a href="https://twitter.com/" title="Kaala On Twitter"
             className="md:w-32 bg-gray-500 tracking-wide text-gray-800 font-bold rounded border-2 border-gray-200 hover:border-gray-600  hover:text-white shadow-md py-2 px-6 inline-flex items-center">
             <span className="mx-auto">Twitter</span>
          </a>
        </div>
        
        
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default ProductMain