import React from 'react'
import Header from './Header'
import ProductMain from './ProductMain'
import Footer from './Footer'
import ProductHero from './ProductHero'

const Product = () => {
  return (
    <>
    <Header/>
    <ProductHero/>
    <ProductMain/>
    <Footer/>
    
    </>
  )
}

export default Product