import React from 'react'
import {Link} from 'react-router-dom'

const Header = () => {
  return (
    <>
    <header className="text-white body-font bg-black font-Mulish">
  <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
    

    <Link to="/"className="flex title-font font-medium items-center text-white mb-4 md:mb-0">
    <svg width="24" height="30" viewBox="0 0 212 275" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M132.257 126.539C132.257 141.314 120.279 153.293 105.503 153.293C90.7274 153.293 78.7489 141.314 78.7489 126.539C78.7489 111.763 90.7274 99.7847 105.503 99.7847C120.279 99.7847 132.257 111.763 132.257 126.539ZM205.877 113.304C191.673 101.13 176.115 111.952 163.264 132.244C150.639 152.178 132.257 162.681 105.503 162.681C78.7485 162.681 60.3667 152.178 47.7414 132.244C34.8902 111.952 19.3322 101.13 5.12835 113.304C-6.58228 123.342 2.42298 145.095 25.42 157.946C48.4179 170.798 63.6627 173.266 78.7485 196.839C92.3841 218.145 88.1844 274.286 105.503 274.286C122.822 274.286 118.622 218.145 132.257 196.839C147.342 173.266 162.588 170.798 185.585 157.946C208.583 145.095 217.588 123.342 205.877 113.304Z" fill="white"/>
<path d="M140.525 96.6908C131.756 86.4174 118.992 80.5244 105.502 80.5244C92.2218 80.5244 79.5863 86.2674 70.8366 96.2784L58.0805 85.1296C70.0453 71.4354 87.3294 63.5822 105.502 63.5822C123.958 63.5822 141.422 71.6407 153.414 85.6937L140.525 96.6908ZM179.984 69.0697C162.01 45.8085 134.864 32.4677 105.502 32.4677C76.2643 32.4677 49.1877 45.7175 31.2142 68.8215L44.5888 79.2229C59.3276 60.2757 81.5287 49.4098 105.503 49.4098C129.576 49.4098 151.838 60.3506 166.576 79.4272L179.984 69.0697ZM207.581 51.7454C183.8 19.344 145.641 0 105.502 0C65.6535 0 27.6599 19.1209 3.86792 51.1483L17.4679 61.2517C38.3863 33.0917 70.4736 16.9422 105.502 16.9422C140.267 16.9422 173.321 33.6997 193.92 61.7691L207.581 51.7454Z" fill="white"/>
</svg>
    
      <span className="ml-3 text-xl">Kaala.ai</span>
    </Link>
    
    <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
      <Link to="/team"className="mr-5 hover:text-gray-500 cursor-pointer ">Team</Link>
      <Link to="/product"className="mr-5 hover:text-gray-500 cursor-pointer ">Product</Link>
      <Link to="/vision"className="mr-5 hover:text-gray-500 cursor-pointer ">Vision</Link>
      <Link to="/contact"className="mr-5 hover:text-gray-500 cursor-pointer ">Contact</Link>
    </nav>
    
  </div>
</header>
   
    
    </>
  )
}

export default Header