import React from "react";
import Vision from "./assets/vision.gif";

const VisionHero = () => {
  return (
    <>
      <section className="text-white font-Mulish body-font bg-black">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="hero"
            src={Vision}
          />
          <div className="text-center lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-300 md:text-xl lg:text-4xl">
              {" "}
              Our Vision{" "}
            </h1>
            <p className="mb-8 leading-relaxed text-xl sm:text-lg ">
              We have one planet Earth with limited resources. That's why it is
              best to have choices for managing, using and sharing natural
              resources within the planets limits to endure food, water, earth and
              energy security for all and this is where we come in. At kaala.ai
              we use adaptive machine learning algorithms to optimize circularity
              of supply chains for sustainability.<br></br><br></br> We have an AI & blockchain
              based collaborative platform for tracking asset flow and
              optimization suggestions to users, primarily focused on waste
              tracking and transparency.
              <br></br><br></br>
              Our regenerative systems focus on keeping waste that are intelligently connected symbiotically within the ecosystem. We will rethink and redesign future conscious way of living using technology to transition to circular economy. It is our duty to get back to nature, improve tree plantation, soil-quality and water management.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default VisionHero;
